export default class Meta {
  title!: string;

  description!: string;

  keywords!: string;

  ogImage!: string;

  headingOne!: string;

  headingTwo!: string;
}
