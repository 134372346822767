export default class BlogTemplate {
  componentId!: string;

  textContent!: string;

  imgSrc!: string;

  href!: string;

  images!: [];
}
