import React, { useContext } from 'react';
import { classNames } from 'primereact/utils';
import { Sidebar } from 'primereact/sidebar';
import { RTLContext } from './App';

const AppRightMenu = (props: any) => {
  const isRTL = useContext(RTLContext);

  const propsLocal = props;

  return (
    <Sidebar
      appendTo="self"
      visible={propsLocal.rightMenuActive}
      onHide={propsLocal.onRightMenuButtonClick}
      position={isRTL ? 'left' : 'right'}
      blockScroll
      showCloseIcon={false}
      baseZIndex={1000}
      className={classNames(
        'layout-rightmenu p-sidebar-sm fs-small p-py-3',
        isRTL ? 'p-pl-0 p-pr-3' : 'p-pl-3 p-pr-0',
      )}
    >
      <div
        className={classNames('online-members p-d-flex p-flex-column p-mt-3', {
          'p-ml-3': !isRTL,
          'p-mr-3': isRTL,
        })}
      >
        <h6 className="header">ONLINE MEMBERS</h6>
        <div className="p-d-flex p-flex-row p-flex-wrap">
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-1.png"
            alt="avatar-1"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-2.png"
            alt="avatar-2"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-3.png"
            alt="avatar-3"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-4.png"
            alt="avatar-4"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-5.png"
            alt="avatar-5"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-6.png"
            alt="avatar-6"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-7.png"
            alt="avatar-7"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-8.png"
            alt="avatar-8"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-9.png"
            alt="avatar-9"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-10.png"
            alt="avatar-10"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-11.png"
            alt="avatar-11"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-12.png"
            alt="avatar-12"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-13.png"
            alt="avatar-13"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-14.png"
            alt="avatar-14"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-15.png"
            alt="avatar-15"
          />
          <img
            className="p-m-1"
            src="assets/demo/images/avatar/avatar-16.png"
            alt="avatar-16"
          />
        </div>
        <span className="p-mt-3">
          <b>+19</b> Costumers
        </span>
      </div>
      <div
        className={classNames('latest-activity p-d-flex p-flex-column p-mt-6', {
          'p-ml-3': !isRTL,
          'p-mr-3': isRTL,
        })}
      >
        <h6 className="header">LATEST ACTIVITY</h6>
        <div className="p-d-flex p-flex-row p-pt-2">
          <i
            className={classNames('pi pi-images p-as-start p-p-2', {
              'p-mr-2': !isRTL,
              'p-ml-2': isRTL,
            })}
          />
          <div className="p-d-flex p-flex-column">
            <span className="p-text-bold p-mb-1">New Sale</span>
            <span className="p-mb-2">
              Richard Jones has purchased a blue t-shirt for $79.
            </span>
            <span className="p-d-flex p-ai-center">
              <img
                className={classNames({ 'p-mr-2': !isRTL, 'p-ml-2': isRTL })}
                src="assets/demo/images/avatar/activity-1.png"
                alt=""
              />
              <small className="muted-text">Emmy Adams, 21.40</small>
            </span>
          </div>
        </div>
        <div className="p-d-flex p-flex-row p-pt-3">
          <i
            className={classNames('pi pi-images p-as-start p-p-2', {
              'p-mr-2': !isRTL,
              'p-ml-2': isRTL,
            })}
          />
          <div className="p-d-flex p-flex-column">
            <span className="p-text-bold p-mb-1">Withdrawal Initiated</span>
            <span className="p-mb-2">
              Your request for withdrawal of $2500 has been initiated.
            </span>
            <span className="p-d-flex p-ai-center">
              <img
                className={classNames({ 'p-mr-2': !isRTL, 'p-ml-2': isRTL })}
                src="assets/demo/images/avatar/activity-2.png"
                alt="avatar-2"
              />
              <small className="muted-text">Emily Walter, 21.40</small>
            </span>
          </div>
        </div>
        <div className="p-d-flex p-flex-row p-pt-3">
          <i
            className={classNames('pi pi-images p-as-start p-p-2', {
              'p-mr-2': !isRTL,
              'p-ml-2': isRTL,
            })}
          />
          <div className="p-d-flex p-flex-column">
            <span className="p-text-bold p-mb-1">Question Received</span>
            <span className="p-mb-2">
              Jane Davis has posted a new question about your product.
            </span>
            <span className="p-d-flex p-ai-center">
              <img
                className={classNames({ 'p-mr-2': !isRTL, 'p-ml-2': isRTL })}
                src="assets/demo/images/avatar/activity-3.png"
                alt="avatar-3"
              />
              <small className="muted-text">Jane Davis, 21.45</small>
            </span>
          </div>
        </div>
      </div>
      <div
        className={classNames('next-events p-d-flex p-flex-column p-mt-6', {
          'p-ml-3': !isRTL,
          'p-mr-3': isRTL,
        })}
      >
        <h6 className="header">NEXT EVENTS</h6>
        <ul>
          <li>
            <i
              className={classNames('pi pi-eye', {
                'p-mr-3': !isRTL,
                'p-ml-3': isRTL,
              })}
            />
            A/B Test
          </li>
          <li>
            <i
              className={classNames('pi pi-video', {
                'p-mr-3': !isRTL,
                'p-ml-3': isRTL,
              })}
            />
            Video Shoot
          </li>
          <li>
            <i
              className={classNames('pi pi-sitemap', {
                'p-mr-3': !isRTL,
                'p-ml-3': isRTL,
              })}
            />
            Board Meeting
          </li>
          <li>
            <i
              className={classNames('pi pi-compass', {
                'p-mr-3': !isRTL,
                'p-ml-3': isRTL,
              })}
            />
            Q4 Planning
          </li>
          <li>
            <i
              className={classNames('pi pi-palette', {
                'p-mr-3': !isRTL,
                'p-ml-3': isRTL,
              })}
            />
            Design Training
          </li>
        </ul>
      </div>
    </Sidebar>
  );
};

export default AppRightMenu;
